function attachAnalyticsAttributes (page) {
  if (!page.includes('/about/community-impact')) {
    return
  }

  const grantMakingLink = document.querySelector(
    'a[href$="/about/community-impact/grant-making"]'
  )
  if (grantMakingLink) {
    grantMakingLink.setAttribute(
      'data-analytics',
      'community-impact--apply-for-grant'
    )
  }

  const volunteerismLink = document.querySelector(
    'a[href$="/about/community-impact/volunteerism"]'
  )
  if (volunteerismLink) {
    volunteerismLink.setAttribute(
      'data-analytics',
      'community-impact--volunteer--learn-more'
    )
  }

  const disasterReliefLink = document.querySelector(
    'a[href$="/about/community-impact/disaster-relief"]'
  )
  if (disasterReliefLink) {
    disasterReliefLink.setAttribute(
      'data-analytics',
      'community-impact--disaster-relief--learn-more'
    )
  }
}

attachAnalyticsAttributes(window.location.pathname)
